@import '../../../Styles/settings';

.ctaButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 0 0 49%;
  margin-top: 24px;
}

.buttonWrapper {
  height: 48px;
  display: flex;
  gap: 10px;
}

.shoppingCartIcon {
  margin-left: 0px;
}

.addToCartWrapper {
  display: flex;
  justify-content: flex-end;
  flex: 1 2 auto;
}

.priceInfoWrapper {
  margin-top: 25px;
}

.priceWrapper {
  margin-right: auto;
  margin-bottom: 5px;
  padding-right: 10px;
  flex: 100 0 auto;
  &.flex {
    align-items: center;
    display: flex;
    padding-right: 0;
  }
}

.priceUnitWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.price {
  @include HelveticaMedium;
  font-size: 24px;
  line-height: 28px;
  color: $midnight-blue;
}

.priceDetails {
  font-size: 12px;
  font-weight: 300;
  color: $text-color-tone-down;
  display: inline-block;
  margin-top: 5px;
}

.loadingDotsWrapper {
  height: 70px;
  display: flex;
  align-items: center;
}

.srpWrapper {
  background-color: $lightest-blue;
  color: $bona-dark-blue;
  font-size: 16px;
  line-height: 20px;
  margin-left: 16px;
  padding: 8px 16px;
}

@media #{$md-up} {
  .priceInfoWrapper {
    margin-top: 0;
  }

  .addToCartWrapper {
    @include styleComponent('style_id_productPage_addToCartBtn') {
      font-size: 18px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .priceWrapper,
  .addToCartWrapper {
    flex: 0 0 auto;
  }

  .priceWrapper {
    margin-bottom: 0;
  }

  .ctaButtons {
    flex-direction: row-reverse;
    justify-content: flex-end;
    flex: 0 1 auto;
  }

  .buttonWrapper {
    display: flex;
    flex: 0 0 49%;
    &.fullWidth {
      flex: 0 0 100%;
    }
  }
}
