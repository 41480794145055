@import '../../Styles/variables';
@import '../../Styles/settings';

.usp {
  --flex-direction: column;
  --container-padding: 32px 24px;

  @media #{$md-up} {
    --flex-direction: row;
    --container-padding: 48px 24px;
  }

  background-color: $bona-light-blue;

  &__inner {
    max-width: $content-container-width + $gutterContentArea * 2;
    margin: 0 auto;
    display: flex;
    flex-direction: var(--flex-direction);
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    padding: var(--container-padding);
  }

  &__item {
    display: flex;
    align-items: center;
    color: $color-brand;
  }

  &__icon {
    margin-right: 24px;
    height: 36px;
    width: 36px;
  }

  &__title {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    @include HelveticaMedium;
    vertical-align: bottom;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }

  &-text-wrapper {
    display: inline-grid;
  }
}
