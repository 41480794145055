@import '../../Styles/settings';

.ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  column-gap: 4px;

  overflow: hidden;
  scroll-behavior: smooth;
}

.contentWrapper {
  min-height: 300px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.tabsContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

li.li {
  border-bottom: 1px solid $color-tabs-nav-border-phone;
  padding: 0;
  font-size: 12px;

  &:first-of-type {
    border-top: 1px solid $color-tabs-nav-border-phone;
  }
}

.btn {
  @include HelveticaMedium;
  color: $midnight-blue;
  width: max-content;
  border: none;
  align-items: center;
  display: flex;
  background: transparent;
  font-size: 16px;
  padding: 16px 0px 16px 0px;
  text-decoration: none;
  letter-spacing: 0px;
  cursor: pointer;
  position: relative;
  z-index: map-get($zIndexes, 'tabsBtn');
  transition: color 0.3s $main-timing-function;
  border-radius: 4px 4px 0px 0px;

  svg {
    margin-right: 8px;
    margin-left: auto;
    height: 16px;
    width: 16px;
    transition: transform 0.2s $main-timing-function;
  }
  &.selected {
    color: $color-tabs-nav-selected;
    svg {
      transform: rotate(180deg);
    }
  }
}

.p {
  margin: 0;
}

.slideDownOuter {
  height: 0;
  overflow: hidden;
  transition: height 0.3s $main-timing-function;
}

.slideDownInner {
  padding-left: 5px;
}

.slideDownExpanded {
  height: auto;
}

.content {
  padding: 15px 0 30px;
}

@media #{$md-up} {
  .content {
    min-height: $min-height-md-up-product-page-tabs;
    max-width: 1158px;
    margin: auto;
    padding: 0 15px;
  }
  .nav {
    padding: 0;
  }
  .ul {
    flex-direction: row;
    margin: 0;
  }
  li.li {
    font-size: 14px;
    padding: 0;
    &:first-of-type {
      border: none;
    }
    border: none;
  }
  .btn {
    background-color: $near-white;
    padding: 16px;
    position: relative;
    transition: padding 0.2s $main-timing-function;

    &:hover,
    &.selected {
      background-color: $clear-blue;
      padding: 16px;
    }

    &.selected {
      &:after {
        background: $color-tabs-nav-selected;
      }
    }
  }
}

@media print {
  .slideDownOuter {
    height: auto;
  }

  .btn {
    color: $color-tabs-nav-selected;
    svg {
      transform: rotate(90deg);
    }
    path {
      fill: $color-tabs-nav-selected;
    }
  }
}
