@import '../../Styles/settings';

.scrollableContainer {
  position: absolute;
  right: 0;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}

.modalContainer {
  background-color: $white;
  padding: 32px 16px;
  width: 100%;
  min-height: 100vh;

  border-radius: 8px 0px 0px 8px;
}

.closeButton {
  cursor: pointer;
  border: none;
  background-color: transparent;
  align-self: flex-end;
  padding: 0 4px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  height: 275px;
}

.image {
  max-height: 275px;
  width: 100%;
  object-fit: contain;
}

.headerContainer {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  margin-bottom: 32px;
  margin-top: 10px;
}

.sparePartTitles {
  flex-direction: column;
}

.title {
  font-size: 20px;
  line-height: 24px;

  margin-bottom: 8px;
}

.subtitle {
  font-size: 12px;
  line-height: 16px;

  color: $darkest-gray;
}

@media #{$md-up} {
  .scrollableContainer {
    width: 474px;
  }

  .title {
    font-size: 24px;
    line-height: 28px;
  }

  .modalContainer {
    padding: 32px;
  }
}
