@import '../../../Styles/settings';

.radioButtonContainer {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.priceWrapper {
  padding-top: 32px;

  @include HelveticaMedium;
  color: $midnight-blue;
  font-size: 24px;
  line-height: 28px;
}

.addToCartWrapper {
  padding-top: 24px;
}

.ctaButtons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.buttonWrapper {
  height: 48px;

  button {
    height: 100%;
  }
}

.buttonContentWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
