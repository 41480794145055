@import '../../../Styles/settings';

.root {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.bgSvg {
  position: absolute;
  top: 100%;
  right: 20px;
  height: 153%;
  min-height: 180px;
  max-width: 300px;
  width: auto;
  fill: $text-color;
  fill-opacity: 0.02;
  transform: translateY(-59%);
  z-index: 0;
}

.documentName{
  margin-bottom: 0;
}

.a {
  @include HelveticaMedium;
  align-items: center;
  line-height: 16px;
  font-size: 12px;
  text-decoration: none;
  display: flex;
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.icon{
  flex-shrink: 0;
  flex-grow: 1;
}

.iconContainer {
  height: 32px;
  width: 32px;
  margin-right: 8px;
  background-color: $clear-blue;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
}

@media #{$md-up} {
  .a {
    cursor: pointer;
    &:before {
      height: 35px;
      width: 35px;
    }
  }
  .bgSvg {
    right: 0;
    transform: none;
    top: 20px;
    height: calc(100% - 40px);
    max-height: 500px;
    max-width: none;
    width: auto;
  }

  .root {
    margin-top: 0;
  }

  :global(.IE) {
    .bgSvg {
      width: 260px;
    }
  }
}
