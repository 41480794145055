@import '../../../Styles/settings';


.uomBtn {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: white;
  cursor: pointer;
  border: 1px solid $midnight-blue;
  border-radius: 4px;
  padding: 4px 8px;
  min-height: 56px;
  min-width: 80px;
}

.btnText {
  color: $midnight-blue;
  font-size: 12px;
  line-height: 16px;
  margin: auto;
}

.isSelected {
  background-color: $midnight-blue;

  .btnText {
    color: white;
  }
}