@import '../../../Styles/settings';

.root {
  position: relative;
}

.rootFlex {
  position: relative;
}

.p {
  max-width: 770px;
  white-space: pre-line;
}

.svg {
  position: absolute;
  top: 100%;
  right: 0;
  height: auto;
  max-width: 300px;
  width: 100%;
  fill: $text-color;
  fill-opacity: 0.02;
  transform: translate(20%, -65%);
  z-index: 0;
}

@media #{$md-up} {

  .rootFlex {
    display: flex;
  }

  .p {
    margin-right: auto;
  }

  .svg {
    transform: none;
    top: 20px;
    max-width: none;
    width: auto;
    height: calc(100% - 40px);
    max-height: 360px;
  }
  :global(.IE) {
    .svg {
      width: 260px;
    }
  }
}
