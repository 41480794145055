@import '../../Styles/settings';

.retailerBlocksContainer {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 24px;
}

.retailHeader {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  margin-top: 32px;
}

.topSection {
  background: $color-product-page-top-section-bg;
  margin-top: $mobile-header-height;
  position: relative;
}

.noInformationSection {
  background: $color-product-page-tabs-md-up;
  padding: 56px 0 0 0;
}

.itemNbr {
  font-size: 10px;
  font-weight: 300;
  display: block;
}

.tabSection {
  background: $color-product-page-top-section-bg;
  padding: 48px 0 40px;
  p,
  td,
  th,
  li {
    font-size: 16px;
    line-height: 20px;
  }
}

.uspSection {
  margin-bottom: -48px;
}

.rootPaddingBottom {
  padding-bottom: $verticleBlockPaddingXS;
}

.contentAreaWrapperPaddingTop {
  padding-top: $verticleBlockPaddingXS;
}

.loadingContainer {
  height: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
}

@media #{$md-up} {
  .uspSection {
    margin-bottom: -80px;
  }

  .retailerBlocksContainer {
    margin-bottom: 0px;
    gap: 24px;
  }

  .rootPaddingBottom {
    padding-bottom: $verticleBlockPaddingMD;
  }

  .topSection {
    margin-top: 0;
    padding-top: $standard-header-height-md-up + 20px;
  }
  .itemNbr {
    font-size: 12px;
    line-height: 0;
  }

  .itemNbrBottom {
    display: none;
  }

  .middleSegment {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    height: 100%;
  }

  .tabSection {
    background: $color-product-page-tabs-md-up;
    min-height: 350px;
    padding: 0;
    margin-bottom: 64px;
    margin-top: 56px;
    p,
    td,
    th {
      font-size: 16px;
    }
  }

  .relatedItemsSection {
    background-color: $color-product-page-top-section-bg;
  }

  .inspirationalSection {
    background: $color-product-page-tabs-md-up;
  }

  .contentAreaWrapperPaddingTop {
    padding-top: $verticleBlockPaddingMD;
  }
}

@media #{$lg-up} {
  .middleSegment {
    padding-left: 9px;
  }

  .inspirationalSection {
    margin: 0 auto;
  }
}

@media print {
  .topSection,
  .relatedItemsSection,
  .inspirationalSection,
  .tabSection {
    display: none;
  }
}
