@import '../../../Styles/settings';

.productLink {
  @include HelveticaMedium;
  color: $midnight-blue;
  font-size: 14px;
  line-height: 114%;
  text-decoration: none;

  display: flex;
  gap: 4px;
  justify-items: center;
  align-items: center;
  padding: 6px 2px;
}

.shortDecription {
  padding-top: 32px;
}

.linkToProduct {
  padding-top: 32px;
}

.cvlProperties {
  font-weight: 400;
  margin: 24px 0;
}

.cvl {
  border-top: 1px solid #dee1e6;
  &:last-of-type {
    border-bottom: 1px solid #dee1e6;
  }
  line-height: 20px;
  padding: 8px 0px;
}

@media #{$md-up} {
  .middleSegment {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    height: 100%;
  }
}
