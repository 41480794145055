@import '../../Styles/settings';

.container {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  border: 1px solid $bona-border-grey;
  position: relative;
  padding: 32px;
}

.aspect {
  position: relative;
  padding-top: 75%;
  overflow: hidden;
  width: 100%;
}

.wrapper {
  cursor: grab;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  max-width: 100%;
  touch-action: none;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.spotContainer {
  position: relative;
  height: 100%;

  img {
    height: 100%;
    pointer-events: none;
    transition: all 0.2s ease-in-out;

    width: -moz-available;
  }
}

.spot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  padding: 16px;
  position: absolute;
  color: white;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: $bona-spot-blue;
    background-blend-mode: multiply;
    opacity: 0.7;
  }

  &.isSelected {
    background-color: $bona-spot-blue;
    background-blend-mode: multiply;
    opacity: 0.7;
    border: 4px solid $light-blue;
  }
}

.plus {
  @include zoomBtn;
  bottom: 64px;
  background-color: $white;
}

.minus {
  @include zoomBtn;
  bottom: 24px;
  background-color: $white;
}

.views {
  display: grid;
  grid-template-columns: repeat(auto-fill, 104px);
  gap: 8px;
  overflow-x: scroll;
  white-space: nowrap;
  margin-bottom: 40px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.viewContainer {
  cursor: pointer;
  position: relative;
  height: 80px;
  width: 104px;
  border-radius: 8px;
  border: 1px solid $light-gray;
  margin-right: 8px;

  &.isActive {
    border: 1px solid $light-blue;
  }

  img {
    position: absolute;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 4px 6px;
  }
}

@media screen and (max-width: $screen-md) {
  .spot {
    height: 12px;
    width: 12px;
    padding: 12px;
  }

  .aspect {
    padding-top: 100%;
  }
}

@media screen and (max-width: $screen-xs) {
  .views {
    gap: 0px;
  }

  .spot {
    height: 6px;
    width: 6px;
    padding: 6px;

    &.isSelected {
      border: 2px solid $light-blue;
    }
  }

  .aspect {
    padding-top: 100%;
  }
}
