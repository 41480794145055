@import '../../../Styles/settings';

.variantTable th,
td {
  min-width: 160px;
  text-align: center;
}

.li::before {
  content: url('../../../NewDesignSVGs/bullet-point-16.svg');
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-8px);
}

.li {
  padding-left: 24px;
  margin-bottom: 16px;
  line-height: 20px;
  position: relative;
  display: block;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 24px;
}

@media #{$md-up} {
  .li {
    padding-left: 24px;
    &:before {
      width: 6px;
      height: 6px;
    }
  }

  .ul {
    margin-bottom: 0;
  }
}
