@import '../../../Styles/settings';

$defaultBaseColor: $pampas;

$defaultHighlightColor: #fff;

@keyframes skeleton {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.line {
  background-color: $defaultBaseColor;
  background-image: linear-gradient(
    90deg,
    $defaultBaseColor,
    $defaultHighlightColor,
    $defaultBaseColor
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 1px;
  display: inline-block;
  line-height: 1;
  animation: skeleton 1.2s ease-in-out 0.7s infinite;
}

.wrapper {
  min-height: 28px;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;

  span {
    display: block;
    margin-top: 15px;
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.width-full {
  width: 100%;
}

.lineHeight-xs {
  height: 10px;
}

.lineHeight-s {
  height: 15px;
}

.lineHeight-m {
  height: 25px;
}

.lineHeight-l {
  height: 50px;
}

.lineHeight-xl {
  height: 100px;
}

.lineWidth-100 {
  width: 100%;
}

.lineWidth-35 {
  width: 100%;
  position: relative;

  &:after {
    content: '';
    display: block;
    background-color: #f2f0ea;
    height: 100%;
    width: 65%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

.lineWidth-35-right {
  width: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    background-color: #f2f0ea;
    height: 100%;
    width: 65%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
}
