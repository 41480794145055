@import '../../../../Styles/settings';

.root {
  table-layout: auto;
  width: 100% !important;
  border-collapse: collapse;

  display: table;

  th,
  td {
    text-align: left;
    position: relative;
    min-width: auto;
    width: fit-content;
  }

  th {
    padding-bottom: 16px;

    &:last-child {
      text-align: center;
    }

    &:nth-child(3) {
      text-align: right;
      padding-right: 32px;
    }
  }

  tr {
    &:not(:first-child) {
      &:hover {
        cursor: pointer;
        background-color: $clear-blue;
      }
    }

    &:nth-child(1) {
      padding-left: 8px;

      background-color: $white;
      color: $midnight-blue;
    }
  }
}

.item {
  border-top: 1px solid $light-cyan;

  td {
    padding: 16px 0;

    &:nth-child(1) {
      width: 168px;
      padding-right: 24px;
      padding-left: 8px;
    }

    &:nth-child(2) {
      font-weight: 600;
      padding-right: 16px;
      color: $midnight-blue;

      &:hover {
        text-decoration: underline;
      }
    }

    &:nth-child(3) {
      padding-right: 32px;
      width: 104px;
    }

    &:nth-child(4) {
      width: 100px;
      padding-right: 8px;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.8;
    }
  }
}

.quantity {
  input {
    padding: 13px 12px 13px 16px;
    margin: 0;
  }

  div {
    top: 8px;
    right: 6px;
  }
}

.arrowUp {
  cursor: pointer;
  position: absolute;
  right: 44px;
  top: 28px;
}

.arrowDown {
  cursor: pointer;
  position: absolute;
  right: 44px;
  bottom: 28px;
}

.addButton,
.detailsButton {
  width: 100% !important;
  border: none;
  background-color: $near-black;
  border-radius: 4px;
  display: flex;
  flex-direction: row !important;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $midnight-blue !important;
  color: $white;
  font-size: 16px;

  &:hover {
    background-color: $darkmidnight_blue !important;
  }

  &.disabled {
    @include disabledButton;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  p {
    font-weight: 600;
  }

  input {
    padding: 16px 36px 16px 16px;
    min-width: 260px;
    border: 1px solid $bona-border-grey;
    border-radius: 4px;
    font-size: 16px;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }

  div {
    position: relative;
  }
}

.actionsContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  justify-content: flex-end;
}

@media screen and (max-width: 992px) {
  .item {
    border-top: none;

    &:not(:last-child) {
      border-bottom: 1px solid $light-cyan;
    }
    td {
      &:nth-child(1) {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 16px;
        font-size: 14px;

        span {
          &:first-child {
            font-weight: 600;
            color: $midnight-blue;
          }

          &:last-child {
            margin-top: 8px;
            font-size: 12px;
          }
        }
      }

      &:nth-child(2) {
        width: 54px;
        padding-right: 8px;
        padding-left: 0;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .quantity {
    width: 54px;
    right: 8px;
    height: 48px;
    text-align: center;
  }

  .addButton {
    width: 54px !important;
    margin: 0 !important;
  }
}

@media screen and (max-width: 480px) {
  .root {
    tr {
      &:hover {
        background-color: unset;
      }
    }
  }
  .item {
    td {
      &:nth-child(1) {
        max-width: 200px;
      }

      &:nth-child(3) {
        padding-right: 0;
      }
    }
  }

  .container {
    div {
      width: 100%;
      margin-bottom: 32px;
    }

    input {
      width: 100%;
    }
  }
}
