@import '../../../Styles/settings';

.root {
  position: relative;
}

.p {
  max-width: 770px;
  white-space: pre-line;
}

.table {
    display: flex;
    width:100%;
    overflow-x: auto;
}

.td {
    text-align: left;
}

.tdHeader {
    text-align: left;
}

.tr {
    flex-direction: column;
    margin-bottom: 8px;
}

.trHeader {
    flex-direction: column;
    margin-top: 4px;
}

.svg {
  position: absolute;
  top: 100%;
  right: 0;
  height: auto;
  max-width: 300px;
  width: 100%;
  fill: $text-color;
  fill-opacity: 0.02;
  transform: translate(20%, -65%);
  z-index: 0;
}

.h6 {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 5px;
}

.tdText {
    margin-bottom: 0px;
}

@media #{$md-up} {
    
 .trHeader {
    display: table-row;
    margin-top: 0px;
 }

 .table {
   overflow-x: initial;
 }

 .tr {
    display: table-row;
 }

 .td {
     padding-right: 42px;
 }

 .svg {
    transform: none;
    top: 20px;
    max-width: none;
    width: auto;
    height: calc(100% - 40px);
    max-height: 360px;
  }
  :global(.IE) {
    .svg {
      width: 260px;
    }
  }

  .h6 {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
