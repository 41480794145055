@import "../../../Styles/settings";

.buttonContentWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}
.mapIcon {
    margin-left: 0px;
}
.loginArea {
    background-color: $bona-light-blue;
    color: $bona-dark-blue;
    padding: 12px;
    border-radius: 4px;
    .button {
        min-width: 200px;
    }
    .content {
        max-width: 424px;
        padding: 4px 12px 12px 12px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            text-align: center;
            line-height: 1.25em;
            padding-top: 8px;
        }
    }
}

.findResellerBtn {
    @include btn;
    transition: background-color 0.3s $main-timing-function;
    width: 100%;
    background-color: $color-find-reseller;
    color: black;
    &:hover {
        background-color: darken($color-find-reseller, 8%);
    }
    &:before {
        content: " ";
        background: url(/Dist/img/icons/store-black.svg) no-repeat;
        background-position: center;
        background-size: contain;
        flex: 1 0 22px;
        height: 22px;
        width: 22px;
        max-width: 22px;
        margin-right: 8px;
    }
}

@media #{$md-up} {
    .findResellerBtn {
        margin-top: auto;
        margin-left: auto;
        width: auto;
        margin-bottom: 50px;
        white-space: nowrap;
        align-self: flex-start;
    }
}
