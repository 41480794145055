.root {
  display: none;
  svg {
    display: none !important;
  }
}
@media print {
  .root {
    display: block;
  }

  .imgWrapper {
    width: 100%;
    text-align: center;
  }
}
