@import '../../../Styles/settings';

.root {
  font-size: 1rem;
  position: relative;
}

.complianceImg {
  width: 50px;
}

.productCompliancesContainer {
  display: flex;
  gap: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 24px;
}

.li::before {
  content: url("data:image/svg+xml; utf8, <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'> <circle cx='8' cy='8' r='8' fill='rgb(0, 79, 224)'/> <path d='M12.4724 5L6.47242 11L4 8.52758' stroke='white' stroke-width='1.27' stroke-linecap='round' stroke-linejoin='round'/> </svg>");
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-8px);
}

.li {
  padding-left: 24px;
  margin-bottom: 16px;
  line-height: 20px;
  position: relative;
  display: block;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.h6 {
  @include HelveticaMedium;

  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0px;
}

.svg {
  position: absolute;
  top: 100%;
  right: 0;
  height: auto;
  max-width: 300px;
  width: 100%;
  fill: $text-color;
  fill-opacity: 0.02;
  transform: translate(20%, -65%);
  z-index: 0;
}

.informationContainer {
  white-space: pre-line;
}

@media #{$md-up} {
  .productCompliancesContainer {
    gap: 48px;
    margin-bottom: 0px;
  }

  .informationContainer {
    max-width: 770px;
  }

  .li {
    padding-left: 24px;
    &:before {
      width: 6px;
      height: 6px;
    }
  }

  .ul {
    margin-bottom: 0;
  }

  .svg {
    transform: none;
    top: 20px;
    height: calc(100% - 40px);
    max-height: 500px;
    max-width: none;
    width: auto;
  }
  :global(.IE) {
    .svg {
      width: 240px;
    }
  }
}

@media print {
  .ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  .li {
    padding-left: 0;
    display: list-item;
    &:before {
      display: none;
    }
  }
  .productCompliancesContainer {
    gap: unset;
  }
}
